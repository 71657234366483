import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";


const PhaseThreeIndex = (props) => {

  return (
    <>
      <div className="story-sec">
        <Container>
          <Row className="align-item-center-theme">
            <Col md={12} xl={5} lg={5} className="resp-order">
              <div className="story-card">
                <div className="story-img-sec">
                  <Image
                    src={window.location.origin + `/assets/img/landing-page/phase-3.png`}
                    className="story-img"
                  />
                </div>
              </div>
            </Col>
            <Col md={12} xl={7} lg={7} className="resp-mrg-btm-md">
              <div className="story-info-sec">
                <div className="story-header">
                  <div className="headig-line"></div>
                  <h5>Phase 3</h5>
                </div>
                <h2>The Future <span>(Post Mint)</span></h2>
                <p>Many of us have seen dream projects promising us the moon but the rocket never takes off… Let’s keep it real, we don’t want to over promise but we have an ambitious plan in the pipeline.</p>
                <p>We want to build our future with the community and the things you want to do.</p>
                <p>What we WILL promise from our side is transparency and clear communication.</p>
                <p>Lace up your sneakers & enjoy the ride</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PhaseThreeIndex;
