import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";


const ProjectIndex = (props) => {

    return (
        <>
            <div className="project-sec" id="project">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={9}>
                            <div className="project-header">
                                <h2>Project</h2>
                                <p>Dr. FrankenSneakers collection consists of 6,000 NFT sneakers that are stored as ERC-721 tokens on
                                    the Ethereum blockchain.</p>
                                <p>With 160 hand drawn traits including 9 unique sneaker models, each NFT is unique and comes with a
                                    membership that gives you access to multiple benefits such as a</p>
                                    <h5>10 years’ of sneakers supply for one
                                        lucky winner.</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-item-center-theme">
                        <Col md={12}>
                            <div className="project-box">
                                <div className="project-card">
                                    <div className="project-info">
                                        <h4>SNEAKERS CULTURE</h4>
                                        <p>
                                        A new way to participate in the culture as a sneaker collector with the opportunity to influence the industry!
                                        </p>
                                    </div>
                                </div>
                                <div className="project-card">
                                    <div className="project-info">
                                        <h4>UNIQUE</h4>
                                        <p>
                                        Once you own an NFT from FrankenSneakers, you will be the only holder of this unique artwork.
                                        </p>
                                    </div>
                                </div>
                                <div className="project-card">
                                    <div className="project-info">
                                        <h4>COMMUNITY</h4>
                                        <p>
                                        Built for the community by the community. Be a part of an ambitious sneakerhead community that is hungry for success.
                                        </p>
                                    </div>
                                </div>
                                <div className="project-card">
                                    <div className="project-info">
                                        <h4>LONG TERM</h4>
                                        <p>Our project will keep evolving and expanding to increase the value of your investment in the long term.</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ProjectIndex;
