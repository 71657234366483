import React, { useState } from "react";
import { Link } from "react-router-dom";
import {Container, Row, Col, Form, Image} from "react-bootstrap";


const MintNowIndex = (props) => {

    return (
        <>
            <div className="mint-now-sec">
                <Container>
                    <Row>
                       <Col md={12}>
                            <div className="mint-now-box">
                                {/* <div className="mint-now-left-card">
                                    <Image
                                        src={window.location.origin + `/assets/img/landing-page/phase-2.png`}
                                        alt="Image"
                                        className="mint-monkey-img"
                                    />
                                </div> */}
                                <div className="mint-now-center-card">
                                    <h4>Mint your FrankenSneaker</h4>
                                    {/* <Form className="mb-0">
                                        <Form.Group className="mb-0" controlId="formBasicEmail">
                                            <Form.Control type="email" placeholder="Enter Your Wallet Address" />
                                        </Form.Group>
                                    </Form> */}
                                    <Link to="#" className="mint-now-btn mt-4">Mint Now</Link>
                                </div>
                                {/* <div className="mint-now-right-card">
                                    <Image
                                        src={window.location.origin + `/assets/img/landing-page/phase-3.png`}
                                        alt="Image"
                                        className="mint-monkey-img"
                                    />
                                </div> */}
                            </div>
                       </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default MintNowIndex;
