import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";


const StoryIndex = (props) => {

    return (
        <>
            <div className="story-sec bg-light-thin" id="story">
                <Container>
                    <Row className="align-item-center-theme">
                        <Col md={12} xl={5} lg={5} className="resp-order">
                            <div className="story-card">
                                <div className="story-img-sec">
                                    <Image
                                        src={window.location.origin + `/assets/img/landing-page/story-img.jpg`}
                                        className="story-img"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={12} xl={7} lg={7} className="resp-mrg-btm-md">
                            <div className="story-info-sec">
                                <div className="story-header">
                                    <div className="headig-line"></div>
                                    <h5>Story</h5>
                                </div>
                                <h2>Welcome To <span>The Club!</span></h2>
                                <p>Like any avid sneakerhead, Dr. FrankenSneakers dream was to create his own collection.</p>
                                <p>Working long days and late nights in his lab, the doctor painstakingly experimented with different designs
                                    to bring his own creation into this world. And one night, voila! He succeeded in bringing his monsters
                                    ALIVE!
                                </p>
                                <p>The doctor’s collection is already a massive hit amongst his sneaker-head friends. Are you ready to be a
                                    part of this community and cop some of the rarest and freakiest sneakers created by the Doc?
                                </p>

                                <a href="https://discord.gg/SuH8duCg" target="_blank" className="join-us-link">
                                    Join Us <i className="fas fa-long-arrow-alt-right ml-2"></i>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default StoryIndex;
