import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";


const PhaseTwoIndex = (props) => {

  return (
    <>
      <div className="story-sec">
        <Container>
          <Row className="align-item-center-theme">
            <Col md={12} xl={7} lg={7} className="resp-mrg-btm-md">
              <div className="story-info-sec story-info-right-sec">
                <div className="story-header">
                  <div className="headig-line"></div>
                  <h5>Phase 2</h5>
                </div>
                <h2>THEY ARE ALIVE! <span>(POST MINT)</span></h2>
                <p>Dr’s creations are now alive &  ALL SOLD OUT!</p>
                <p><strong>10 years’ of sneaker supply for one lucky holder</strong> – so get  ready to make some space in your
                  closest.</p>

                <p>One of our first steps is to create a community fund which will be used to continue the development of the project.</p>

                <p>At FSC we believe in the power to give back. The Dr. loves his creatures, but he loves animals
                  even more. We will give a percentage of our earnings to an association that protects wildlife.</p>

                <p>The Dr. will now Unleash his minions to the FSC holders … and these little monsters can create
                  more than what you think…</p>
              </div>
            </Col>
            <Col md={12} xl={5} lg={5} className="resp-mrg-btm-md">
              <div className="story-card">
                <div className="story-img-sec">
                  <Image
                    src={window.location.origin + `/assets/img/landing-page/phase-2.png`}
                    className="story-img"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PhaseTwoIndex;
