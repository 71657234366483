import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const HeaderIndex = (props) => {

  return (
    <>
      <div className="header-sec">
        <Navbar expand="lg">
          <Container>
            <Link to="/">
              <Image
                src={window.location.origin + `/assets/img/header-logo.png`}
                alt="Image"
                className="landing-logo"
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                src={window.location.origin + `/assets/img/landing-page/menu.svg`}
                alt="Image"
                className="landing-logo"
              />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link href="#story" className="smooth">Story</Nav.Link>
                <Nav.Link href="#project" className="smooth">Project</Nav.Link>
                <Nav.Link href="#roadmap" className="smooth">RoadMap</Nav.Link>
              </Nav>
              <Nav className="mr-auto resp-flex">
                <ul className="list-unstyled header-social-link">
                  <li>
                    <a href="https://discord.gg/SuH8duCg" target="_blank">
                      <Image
                        src={window.location.origin + `/assets/img/landing-page/discord-icon.svg`}
                        alt="Image"
                        className="head-social-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/frankensneakers" target="_blank">
                      <Image
                        src={window.location.origin + `/assets/img/landing-page/twitter-icon.svg`}
                        alt="Image"
                        className="head-social-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/frankensneakers/" target="_blank">
                      <Image
                        src={window.location.origin + `/assets/img/landing-page/instagram-icon.svg`}
                        alt="Image"
                        className="head-social-icon"
                      />
                    </a>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default HeaderIndex;
