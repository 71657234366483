import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";


const PhaseOneIndex = (props) => {

  return (
    <>
      <div className="story-sec">
        <Container>
          <Row className="align-item-center-theme">
            <Col md={12} xl={5} lg={5} className="resp-order">
              <div className="story-card">
                <div className="story-img-sec">
                  <Image
                    src={window.location.origin + `/assets/img/landing-page/phase-1.png`}
                    className="story-img"
                  />
                </div>
              </div>
            </Col>
            <Col md={12} xl={7} lg={7} className="resp-mrg-btm-md">
              <div className="story-info-sec">
                <div className="story-header">
                  <div className="headig-line"></div>
                  <h5>Phase 1</h5>
                </div>
                <h2>Welcome to <span>Frankensneakers Club (PRE-MINT)</span></h2>
                <p>   Welcome to the club. Just like our favorite sneaker drops, get ready to jump in line to cope your FSC NFT.
                  Grind hard to be part of our whitelist which will secure you a spot to buy an NFT</p>
                <p>During this phase we will also conduct Giveaways & connect with the community via our social platforms
                  – so watch this space.
                </p>
                <p>The Dr. is hungry for new creations and owning an NFT from FSC will be a golden ticket to be part of this
                  exciting new world!
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PhaseOneIndex;
