import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";
import FooterIndex from "./FooterIndex";
import HomeBannerIndex from "./HomeBannerIndex";
import PhaseOneIndex from "./PhaseOneIndex";
import PhaseThreeIndex from "./PhaseThreeIndex";
import PhaseTwoIndex from "./PhaseTwoIndex";
import ProjectIndex from "./ProjectIndex";
import RoadMapIndex from "./RoadMapIndex";
import StoryIndex from "./StoryIndex";
import MintNowIndex from "./MintNowIndex";

const LandingPageIndex = (props) => {

  return (
    <>
      <div className="landing-page">
        <HomeBannerIndex/>
        <StoryIndex/>
        <ProjectIndex/>
        <RoadMapIndex/>
        <PhaseOneIndex/>
        <PhaseTwoIndex/>
        <MintNowIndex/>
        <PhaseThreeIndex/>
        <FooterIndex/>
      </div>
    </>
  );
};

export default LandingPageIndex;
