import './App.css';
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Base />
  );
}

export default App;
