import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Media } from "react-bootstrap";


const FooterIndex = (props) => {

  return (
    <>
      <div className="footer-landing-sec">
        <Container>
          <Row className="align-items-center">
            <Col md={4} className="display-mobile-hide">
              <div className="footer-left-img-sec">
                <Image
                    src={window.location.origin + `/assets/img/landing-page/footer-left-img.png`}
                    className="footer-left-img"
                  />
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-card">
                <div className="footer-logo-sec">
                  <Image
                    src={window.location.origin + `/assets/img/header-logo.png`}
                    className="footer-logo"
                  />
                </div>
                <ul className="social-icons-link-sec list-unstyled">
                  <li>
                    <a href="https://discord.gg/SuH8duCg" target="_blank">
                      <Image
                        src={window.location.origin + `/assets/img/landing-page/discord-icon.svg`}
                        alt="Image"
                        className="head-social-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/frankensneakers" target="_blank">
                      <Image
                        src={window.location.origin + `/assets/img/landing-page/twitter-icon.svg`}
                        alt="Image"
                        className="head-social-icon"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/frankensneakers/" target="_blank">
                      <Image
                        src={window.location.origin + `/assets/img/landing-page/instagram-icon.svg`}
                        alt="Image"
                        className="head-social-icon"
                      />
                    </a>
                  </li>
                </ul>
                <div className="terms-services-sec">
                  <Link to="#">Terms of Service</Link>
                </div>
                <div className="copy-right-sec">
                  <h5>© 2023 The Franken Sneakers Club. All rights reserved</h5>
                </div>
              </div>
            </Col>
            <Col md={4} className="display-mobile-hide">
              <div className="footer-right-img-sec">
                <Image
                    src={window.location.origin + `/assets/img/landing-page/footer-right-img.png`}
                    className="footer-right-img"
                  />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FooterIndex;
