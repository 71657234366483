import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col } from "react-bootstrap";

const HomeBannerIndex = (props) => {

  return (
    <>
      <div className="home-banner-img-sec">
        <Container>
          <Image
              src={window.location.origin + `/assets/img/landing-page/banner-img.jpg`}
              className="home-banner-img"
          />
        </Container>
        {/* <Image
            src={window.location.origin + `/assets/img/landing-page/home-banner-mobile.png`}
            className="home-banner-img-mobile"
        /> */}
        {/* <Image
            src={window.location.origin + `/assets/img/landing-page/home-banner-mobile-landscape.png`}
            className="home-banner-img-mobile"
        /> */}
      </div>
    </>
  );
};

export default HomeBannerIndex;
