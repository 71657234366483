import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";


const RoadMapIndex = (props) => {

  return (
    <>
      <div className="roadmap-sec bg-light-thin" id="roadmap">
        <Container>
          <Row className="justify-content-md-center">
            <Col md={9}>
              <div className="project-header">
                <h2>Road Map</h2>
                <p>Sneaker culture is beyond just shoes, it is about people, community, music, art & having fun.
                  The goal is to be the number one sneaker culture community in the METAVERSE & our roadmap outlines
                  some of the future perk’s members will be able to access.
                </p>
                <p>
                  Scroll through the different phases to look over some of the ideas and concepts we have envisioned to
                  grow the community.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default RoadMapIndex;
